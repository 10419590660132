import React, { useState, useEffect } from 'react'
import { Button, Toast } from 'antd-mobile'
import api from '../../https/api'
export default function BtnCountdown({initialSeconds=59,phone,isCaptcha=true}) {
  const [disabled,setDisabled] = useState(true)
  const [text,setText] = useState('验证码')

  useEffect(()=>{
    if(/^1[3456789]\d{9}$/.test(phone) && isCaptcha){
      setDisabled(false)
    }else{
      setDisabled(true)
    }

  },[phone,isCaptcha])
 
  const countdown =()=>{
    let seconds = initialSeconds
    const timer = setInterval(()=>{
      setText(seconds + ' 秒')
      if(seconds === 0){
        clearInterval(timer)
        setDisabled(false)
        setText('验证码')
      }
      seconds--
    },1000)
  } 

  const getCode = async () => {
    if(!/^1[3456789]\d{9}$/.test(phone)){
      Toast.show('手机号码格式错误')
    }
    countdown()
    setDisabled(true)
    const {data:{code,data,msg}} = await api.getPhoneCode({phone})

    if(code === 0){
      let phoneCode = {
        phone,
        code:data.verifyCode
      }
      localStorage.setItem('phoneCode',JSON.stringify(phoneCode))
      Toast.show({
        icon: 'success',
        content: '发送成功,请注意查收',
      })
    }else{
      Toast.show({
        icon: 'fail',
        content: msg,
      })
    }
    /* const {code,msg} = JSON.parse(data)
    if(code === 200){
      let phoneCode = {
        phone,
        code:msg
      }
      localStorage.setItem('phoneCode',JSON.stringify(phoneCode))
      Toast.show({
        icon: 'success',
        content: '发送成功,请注意查收',
      })
    }else{
      Toast.show({
        icon: 'fail',
        content: msg,
      })
    } */
  }
  return (
    <Button 
      color='danger' 
      size='small'
      disabled={disabled}
      onClick={() =>getCode()}
    >
      {text}
    </Button>
  )
}
