import React, { Suspense, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { NavBar, Result, Skeleton } from 'antd-mobile'

const ua = navigator.userAgent.toLowerCase()
//const ua = 'micromessenger'
export default function Outside() {
  const [title,setTitle] = useState(null)
  const { pathname } = useLocation()

  useEffect(() => {
      if(pathname.includes('car')){
        setTitle('预约试驾')
      }else if(pathname.includes('coupon')){
        setTitle('建行微信立减金')
        document.title = '建行微信立减金'
      }else if(pathname.includes('signin')){
        let text = pathname.includes('register') ? '感谢参加工银家装节' : '工银家装节'
        document.title = text
        setTitle(text)
      }else if(pathname.includes('lottery')){
        document.title = '福利大放送'
        setTitle('预约成功')
      }
  },[pathname])
  return (
    <>  
      {
        ua.includes('micromessenger') ? (
          <Suspense fallback={
              <>
                <Skeleton.Title animated />
                <Skeleton.Paragraph lineCount={5} animated />
              </>
            }
          >
            {
              !pathname.includes('lottery') ? (
                <NavBar className='crm-header' backArrow={null}>{title}</NavBar>
              ) : null
            }
            <Outlet/>
          </Suspense>
        ) : (
          <Result
            status='warning'
            title='警告'
            description='请在微信内打开页面'
          />
        )
      }
    </>
  )
}